import React from "react";
import DTLogo from "../components/logo";

export default () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-black text-white pt-6">
      <DTLogo color="white" className="w-12 md:w-16" />
      <footer className="flex flex-col items-center py-4">
        © {new Date().getFullYear()}, DeepTek LLC
      </footer>
    </div>
  );
};
